export default function ArtStationLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 520.7 93.1"
      viewBox="0 0 520.7 93.1"
      width="130.175"
      height="23.275"
    >
      <g fill="#13aff0">
        <path d="m0 71.8 8.9 15.4c1.8 3.5 5.4 5.9 9.5 5.9h59.3l-12.2-21.3z" />
        <path d="m105.8 71.9c0-2.1-.6-4.1-1.7-5.8l-34.8-60.4c-1.8-3.4-5.3-5.7-9.4-5.7h-18.4l53.7 93 8.5-14.7c1.6-2.8 2.1-4 2.1-6.4z" />
        <path d="m56.7 56.6-24-41.5-24 41.5z" />
      </g>
    </svg>
  );
}
