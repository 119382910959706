import { useEffect } from "react";
import { useCollection } from "../../hooks/firestore/useCollection";
import ContentBlock from "../../components/contentBlock/ContentBlock";

import styles from "./Blogs.module.css";

export default function Blogs() {
  const { documents: allBlogs, error, isPending } = useCollection("blogs");

  console.log("allBlogs", allBlogs);

  return (
    <div
      className={styles.blogs}
      style={{ backgroundColor: "white", color: "black" }}
    >
      <h1>News</h1>

      {error && <p>{error}</p>}
      {isPending && <p>Loading...</p>}

      {/* All blog entries in the collection */}
      {allBlogs &&
        allBlogs
          .sort((a, b) => b.created_at.toMillis() - a.created_at.toMillis())
          .map((doc) => (
            <div key={doc.id}>
              <BlogEntry doc={doc} />
            </div>
          ))}
    </div>
  );
}

function BlogEntry({ doc }) {
  const stories = doc.stories || [];
  return (
    <div>
      {/* TODO: use proper blog date */}
      <h2>{doc.id}</h2>

      {/* All stories in the blog entry */}
      {stories.map((story, index) => (
        <Story key={index} parentId={doc.id} story={story} />
      ))}
    </div>
  );
}

function Story({ parentId, story }) {
  const blocks = story.blocks || [];
  // internal anchor for each story is constructed from the parentId and story id,
  // e.g. if the story is in a blog entry with id "2023-10-01" and the story id is "gdc-2025",
  // the internalId will be "2023-10-01-gdc-2025"
  // this is used to scroll to the story when the hash in the URL matches the internalId
  const internalId = `${parentId}-${story.id}`;

  // Scroll to the element if the hash matches the anchor ID
  useEffect(() => {
    if (window.location.hash === `#${internalId}`) {
      const element = document.getElementById(internalId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [internalId]);

  return (
    <div id={internalId}>
      {/* Title */}
      <SectionHeader internalId={internalId} title={story.title} />
      {/* Content blocks */}
      {blocks.map((block, index) => (
        <ContentBlock key={index} inner={block} />
      ))}
    </div>
  );
}

function SectionHeader({ internalId, title }) {
  return (
    // show no title if the title is empty
    title && (
      <div>
        <h3 className={styles["story-title"]}>
          {/* Copy link button */}
          <button
            className={styles["copy-link-button"]}
            onClick={() => {
              const url = `${window.location.origin}${window.location.pathname}#${internalId}`;
              navigator.clipboard.writeText(url).then(() => {
                alert(`Đã copy đường dẫn vào clipboard:\n${url}`);
              });
            }}
          >
            🔗
          </button>{" "}
          {title}
        </h3>
      </div>
    )
  );
}
