import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./Subscription.module.css";

const CLOUD_FN_EMULATOR_PORT = 5003; // local development

const UnsubscribeConfirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const { token, email, source } = Object.fromEntries(params.entries());

  useEffect(() => {
    if (!email || !token) {
      navigate("/courses");
    }
  }, [email, navigate]);

  const obscureEmail = (email) => {
    if (!email) return "";
    const [name, domain] = email.split("@");
    const obscuredName =
      name[0] + "*".repeat(name.length - 2) + name[name.length - 1];
    return `${obscuredName}@${domain}`;
  };

  const handleUnsubscribe = async () => {
    try {
      const url = `${getEndpoint(
        "unsubscribe",
        true, // skipping CloudFn emulator even in localhost
        CLOUD_FN_EMULATOR_PORT
      )}/?token=${token}&email=${email}&source=${source}`;

      const response = await fetch(url);
      if (response.ok) {
        navigate(`/unsubscribe-success?email=${encodeURIComponent(email)}`, {
          replace: true,
        });
      } else {
        const errorText = await response.text();
        alert(
          `Khâu unsubscribe bị lỗi, vui lòng thử lại. Chi tiết: ${errorText}`
        );
      }
    } catch (error) {
      alert("Không thể kết nối với CloudFn 😭, có gì đó không ổn");
    }
  };

  return (
    <div>
      <h2 className={styles.center}>Quyết định unsubscribe? 🥲</h2>
      <p className={styles.center}>Email: {obscureEmail(email)}</p>
      <p className={styles.center}>
        Chúng tớ hy vọng bạn sẽ ở lại, nhưng nếu bạn đã muốn chia tay thì đành
        vậy...
      </p>
      <div className={styles.center}>
        <button className="btn" onClick={handleUnsubscribe}>
          Xác nhận
        </button>
      </div>
      <p className={styles.center}>
        Nếu chấp thuận bạn sẽ không nhận email từ DPGP nữa 🥺
      </p>
    </div>
  );
};

export default UnsubscribeConfirmation;

const getEndpoint = (route, skip_emulator = false, localPort = 5001) => {
  const CLOUD_FN_ENDPOINT =
    skip_emulator || window.location.hostname !== "localhost"
      ? "https://asia-southeast1-dpgp-techart.cloudfunctions.net/api"
      : `http://127.0.0.1:${localPort}/dpgp-techart/asia-southeast1/api`;

  return `${CLOUD_FN_ENDPOINT}/${route}`;
};
