import styles from "./ContentBlock.module.css";

export default function File({ label, href }) {
  return (
    <div className={styles.download}>
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        download={label || "Resources"}
      >
        📎 {label || "Resources"}
      </a>
    </div>
  );
}
