export default function ButtonLink({ label, href }) {
  return href ? (
    <a href={href} target="_blank" rel="noreferrer noopener">
      <button className="btn">{label}</button>
    </a>
  ) : (
    // no anchor if href ("data" field) is not provided
    <button className="btn">💀💀💀 NO HREF</button>
  );
}
