import { useState } from "react";
import CryptoJS from "crypto-js";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  writeBatch,
} from "firebase/firestore";
import MailBlocks from "./MailBlocks";

import styles from "./Admin.module.css";

const db = getFirestore();

export default function SendNewsletter() {
  const [collapsed, setCollapsed] = useState(true);
  const [formData, setFormData] = useState({
    cc: [],
    sendingMethod: "direct",
    template: "newsletter_v1",
    subject: "",
    content: "",
    ignoreOfficialList: true,
    dryRun: true,
  });
  const [blocks, setBlocks] = useState([{ type: "header", data: "" }]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]:
          name === "cc"
            ? value.split(",").map((item) => item.trim())
            : name === "content"
            ? e.target.value // Preserve all HTML tags in content
            : name === "template"
            ? value
            : type === "checkbox"
            ? checked
            : value,
      };
    });

    // console.log(`Updated ${name} to ${type === "checkbox" ? checked : value}`);
  };

  const handleSend = async (event) => {
    event.preventDefault();

    try {
      const {
        cc,
        sendingMethod,
        template,
        subject,
        content,
        ignoreOfficialList,
        dryRun,
      } = formData;

      // Retrieve the mailing list
      const emails = await getMailingList(cc, ignoreOfficialList);
      console.log(`Total emails in mailing list: ${emails.length}`);

      if (emails.length === 0) {
        console.warn("No emails to send to.");
        return;
      }

      // Manually apply CSS to content if sendingMethod is "direct"
      const finalContent =
        sendingMethod === "direct" ? applyCSSPerBlock(blocks, true) : content;

      console.log("🤖 Preparing to send newsletter with data:", {
        ...formData,
        content: finalContent,
      });

      // Send newsletters in batches
      await sendNewsletterBatchesWithInterval(
        emails,
        sendingMethod,
        template,
        subject,
        finalContent,
        dryRun
      );
      console.log(`${dryRun ? "Dry run: " : ""}All batches sent successfully.`);
    } catch (error) {
      console.error("Error sending newsletter:", error);
    }
  };

  const applyCSSPerBlock = (draftBlocks, loremIpsum = false) => {
    let hasParagraph = draftBlocks.some(
      (block) => block.type === "paragraph" && block.data.trim() !== ""
    );

    if (!hasParagraph) {
      if (loremIpsum) {
        draftBlocks.push({
          type: "paragraph",
          data: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
        });
      }
    }

    return draftBlocks
      .map((block) => {
        if (block.type === "header") {
          // section header
          return `<p class="SectionHeader">${block.data}</p>`;
        } else if (block.type === "image") {
          return (
            // image with optional width and credit
            `<img class="BannerImage" src="${block.data}" width="${
              block.img_width || 360
            }"/>` +
            // image credit
            (block.img_credit
              ? `<p class="ImageCredit">${block.img_credit}</p>`
              : "")
          );
        } else if (block.type === "paragraph") {
          //   retain the newline by replacing it with <br>
          return `<p class="SectionContent">${block.data.replace(
            /\n/g,
            "<br>"
          )}</p>`;
        }
        return "";
      })
      .join("");
  };

  const label = `${collapsed ? "📩" : "📨"} Send Newsletter`;

  return (
    <div>
      <button
        className={styles.collapsibleHeader}
        onClick={() => setCollapsed(!collapsed)}
      >
        {label}
      </button>

      {!collapsed && (
        <div className={collapsed ? "" : styles.section}>
          <form>
            <div>
              <label>
                Sending Method:
                <div>
                  <label>
                    <input
                      type="radio"
                      name="sendingMethod"
                      className={styles.checkbox}
                      value="template"
                      checked={formData.sendingMethod === "template"}
                      onChange={handleChange}
                    />
                    Use Template (predefined in Firestore)
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="sendingMethod"
                      className={styles.checkbox}
                      value="direct"
                      checked={formData.sendingMethod === "direct"}
                      onChange={handleChange}
                    />
                    Send Directly
                  </label>
                </div>
              </label>
            </div>
            {formData.sendingMethod === "template" && (
              <div>
                <label>
                  Template:
                  <div>
                    <select
                      name="template"
                      value={formData.template}
                      onChange={handleChange}
                    >
                      <option value="css_test">CSS test</option>
                      <option value="newsletter_v1">
                        Newsletter version 1
                      </option>
                    </select>
                  </div>
                </label>
              </div>
            )}
            <div>
              <label>
                CC:
                <div>
                  <input
                    type="text"
                    name="cc"
                    placeholder="phân cách bằng dấu phẩy"
                    value={formData.cc}
                    onChange={handleChange}
                  />
                </div>
              </label>
            </div>
            <div>
              <label>
                Subject:
                <div>
                  <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                  />
                </div>
              </label>
            </div>
            <div>
              <label>
                {formData.sendingMethod === "template"
                  ? "Content (⚠️ limited HTML support)"
                  : "Content (HTML)"}
                :
                {formData.sendingMethod === "template" ? (
                  <div>
                    <textarea
                      name="content"
                      placeholder="bản tin tháng 3/2025"
                      value={formData.content}
                      onChange={handleChange}
                      rows="16"
                    />
                  </div>
                ) : (
                  <MailBlocks blocks={blocks} setBlocks={setBlocks} />
                )}
              </label>
            </div>

            <div>
              <label>
                <input
                  type="checkbox"
                  className={styles.checkbox}
                  name="ignoreOfficialList"
                  checked={formData.ignoreOfficialList}
                  onChange={handleChange}
                />
                Ignore official mailing list 👭
              </label>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  className={styles.checkbox}
                  name="dryRun"
                  checked={formData.dryRun}
                  onChange={handleChange}
                />
                Dry run (no emails sent to users) 🧩
              </label>
            </div>
            <button
              type="submit"
              className="btn"
              onClick={handleSend}
              disabled={!formData.subject}
            >
              Send
            </button>
          </form>
        </div>
      )}
    </div>
  );
}

const getEndpoint = () => {
  // redirect to a specific route in the frontend to show confirmation page
  return window.location.hostname === "localhost"
    ? "http://localhost:3000/unsubscribing"
    : "https://school.dauphaigiaiphau.wtf/unsubscribing";
};

/**
 * Retrieves a list of unique email addresses from the "users",
 * "enrollment_migration", and "subscriptions" collections in Firestore.
 *   - `users` collection contains all registered users, and may contain guests
 *     who have not enrolled in any course. This is dynamic and may change over
 *     time.
 *   - `enrollment_migration` contains students from the old enrollment systems.
 *     This is static and will not change.
 *   - `subscriptions` contains anyone who have opted in for receiving emails.
 * @param {string[]} cc - An array of email addresses to CC on the newsletter.
 * @param {boolean} dryRun - Whether to skip big collections for testing.
 * @returns {Promise<string[]>} A promise that resolves to an array of unique
 * email addresses.
 */
const getMailingList = async (cc = [], ignoreOfficialList = false) => {
  const emails = new Set(cc);
  const subscriptionsSnapshot = await getDocs(collection(db, "subscriptions"));

  subscriptionsSnapshot.forEach((doc) => {
    const data = doc.data();
    // for now we care about digests subscription only
    if (data.opting_in) {
      if (data.opting_in.digests === true) {
        if (!ignoreOfficialList) {
          // anyone who explicitly opted in for receiving emails
          emails.add(doc.id);
        }
      } else {
        console.log(`🤷‍♂️ User opted out of digests: ${doc.id}`);
        emails.delete(doc.id); // overrides CC list
      }
    }
  });

  // if (!ignoreOfficialList) {
  //   // massive collections, so we need to be careful
  //   const usersSnapshot = await getDocs(collection(db, "users"));
  //   const enrollmentSnapshot = await getDocs(
  //     collection(db, "enrollment_migration")
  //   );

  //   usersSnapshot.forEach((doc) => {
  //     const email = doc.data().email;
  //     if (email) {
  //       emails.add(email);
  //     }
  //   });

  //   enrollmentSnapshot.forEach((doc) => {
  //     emails.add(doc.id);
  //   });
  // }

  return Array.from(emails);
};

/**
 * Sends a batch of newsletter emails, using template `newsletter_v1`.
 * @param {string[]} emails - The batch of email addresses to send the
 * newsletter to.
 * @returns {Promise<void>}
 */
const sendBatchNewsletter = async (
  emails,
  sendingMethod,
  template,
  subject,
  content,
  dryRun = false
) => {
  const batch = writeBatch(db);
  emails.forEach((email) => {
    const token = generateSubscriptionToken(email, false);
    // the query string needs the token and email
    const unsubscribeLink = `${getEndpoint()}/?token=${token}&email=${encodeURIComponent(
      email
    )}&source=mail-embedded-link`;

    console.log(`Generating unsubscribe link for ${email}: ${unsubscribeLink}`);
    console.log(
      `-------- ${dryRun ? "Dry run: " : ""}Sending email to: ${email}`
    );

    if (!dryRun) {
      const docRef = doc(collection(db, "mail"));

      if (sendingMethod === "direct") {
        // applying HTML wrapping directly
        const htmlContent = DIRECT_HTML.replace("{{title}}", subject)
          .replace("{{content}}", content)
          .replace("{{unsubscribeLink}}", unsubscribeLink);

        batch.set(docRef, {
          to: email,
          message: {
            subject: subject,
            html: htmlContent,
          },
        });
      } else {
        // using Firestore mail template
        batch.set(docRef, {
          to: email,
          template: {
            name: template,
            data: {
              subject: subject,
              content: content,
              unsubscribeLink: unsubscribeLink,
            },
          },
        });
      }
    }
  });
  await batch.commit();
};

/**
 * Sends a batch of newsletter emails, then waits for a specified interval
 * before sending the next batch.
 * @param {string[]} emails - The list of email addresses to send the newsletter
 * to.
 * @param {number} batchSize - The number of emails to send in each batch.
 * @param {number} interval - The interval in milliseconds to wait between
 * batches.
 * @returns {Promise<void>}
 */
const sendNewsletterBatchesWithInterval = async (
  emails,
  sendingMethod,
  template,
  subject,
  content,
  dryRun = false,
  batchSize = 100,
  interval = 5000
) => {
  for (let i = 0; i < emails.length; i += batchSize) {
    const batch = emails.slice(i, i + batchSize);
    const batchNumber = `${i / batchSize + 1}`;

    console.log(
      `---- ${dryRun ? "Dry run: " : ""}Submitting batch #${batchNumber} with ${
        batch.length
      } emails`
    );

    await sendBatchNewsletter(
      batch,
      sendingMethod,
      template,
      subject,
      content,
      dryRun
    );

    console.log(
      `---- ${
        dryRun ? "Dry run: " : ""
      }Batch #${batchNumber} submitted successfully.`
    );

    if (i + batchSize < emails.length) {
      await new Promise((resolve) => setTimeout(resolve, interval));
    }
  }
};

/**
 * Generates a secure token with a secret key for email subscription or
 * unsubscription.
 * @param {string} email - The email address to generate the token for.
 */
const generateSubscriptionToken = (email, optingIn) => {
  const SECRET_KEY = optingIn
    ? process.env.REACT_APP_EMAIL_SUBSCRIPTION_SECRET_KEY
    : process.env.REACT_APP_EMAIL_UNSUBSCRIPTION_SECRET_KEY;

  const utf8Email = CryptoJS.enc.Utf8.parse(email.trim());
  const utf8Key = CryptoJS.enc.Utf8.parse(SECRET_KEY.trim());

  return CryptoJS.HmacSHA256(utf8Email, utf8Key).toString();
};

const DIRECT_HTML = `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">

<html xmlns="http://www.w3.org/1999/xhtml">
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>{{title}}</title>
    <style type="text/css">
        @import url('https://fonts.googleapis.com/css2?family=Ultra&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Literata:wght@400;700&display=swap');
        #outlook a {padding:0;}
        .Leaflet {
            max-width: 480px;
            margin: 0 auto;
        }
        .SectionHeader {
            text-align: center; 
            font-family: "Ultra", serif;
            font-weight: 400;
            font-style: normal;
            margin: 20px 0;
        }
        .BannerImage {
            display: block;
            margin: 0 auto;
        }
        .ImageCredit {
            text-align: center;
            font-family: 'Literata', serif; 
            font-size: 10px;
            margin: 0 0;
        }
        .SectionContent {
            font-family: 'Literata', serif; 
            font-size: 16px; 
            margin: 0 auto;
        }
        .ActionAnchor {
            background: rgb(204, 174, 0);
            color: #ffffff;
            padding: 4px 40px;
            margin: 0 10px;
            border: none;
            border-radius: 8px;
            font-weight: bold;
            font-size: 1em;
            text-decoration: none;
        }
        .Footer {
            font-family: 'Literata', serif; 
            font-size: 12px;
            text-align: center;
        }
    </style>
    <!--[if IEMobile 7]>
    <style type="text/css">
        /* Targeting Windows Mobile */
    </style>
    <![endif]-->
    <!--[if gte mso 9]>
    <style>
        /* Target Outlook 2007 and 2010 */
    </style>
    <![endif]-->
</head>
<body>
<div class="Leaflet">
{{content}}
</div>
<br><br>
<p class="Footer">Bạn không còn muốn nhận những email này nữa?<br>Unsubscribe <a href="{{unsubscribeLink}}">ở đây nhé</a>.</p>
</body>
</html>
`;
